import { QUESTIONS_DATA } from '../../constants/actionTypes';

const initialState = [];
const questionsReducer = (state, action) => {
  switch (action.type) {
    case QUESTIONS_DATA:
      return action.payload;

    default:
      return [];
  }
};

export default questionsReducer;
