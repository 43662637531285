import { combineReducers } from 'redux';
import authReducer from './authReducer';
import analysisResult from './analysisResult';
import cameraImages from './cameraImages';
import questionsReducer from './questionsReducer';
const rootReducer = combineReducers({
  authReducer,
  analysisResult,
  cameraImages,
  questionsReducer,
});

export default rootReducer;
