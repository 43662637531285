import { useSelector } from "react-redux";

export default function useProvideAuth() {
  let { isLogged } = useSelector((store) => store.authReducer);
  const isRemember =
    !!localStorage.getItem("remember") || !!sessionStorage.getItem("remember");

  const signin = (cb) => {
    if (typeof cb === "function") {
      cb();
    }
  };

  const signout = (cb) => {
    if (typeof cb === "function") {
      cb();
    }
  };

  return {
    isLogged,
    isRemember,
    signin,
    signout,
  };
}
