import { Suspense, useEffect } from "react";
import loadable from "@loadable/component";
import { Route, Switch, useHistory } from "react-router-dom";
import "./App.css";
import { HOME, REGISTERATION } from "./routes/routeNames";
import { CssBaseline } from "@mui/material";

const Header = loadable(() => import("./components/Header"));
const Layout = loadable(() => import("./components/Layout"));
const Home = loadable(() => import("./pages/Homepage"));
const NewForm = loadable(() => import("./pages/Registration/NewForm"));
const AppRoutes = loadable(() => import("./routes/AppRoutes"));

function App() {
  const history = useHistory();

  useEffect(() => {
    window.location.pathname === "/" && history.push(HOME);
  }, [history]);

  return (
    <Suspense fallback="Loading...">
      <CssBaseline />
      <div className="App">
        <Header />
        <Layout>
          <Switch>
            <Route exact path={HOME} component={Home} />
            <Route exact path={REGISTERATION} component={NewForm} />
            <AppRoutes />
          </Switch>
        </Layout>
      </div>
    </Suspense>
  );
}

export default App;
