import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ProvideAuth } from './utils/ProvideAuth';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import './i18n';
import ThemeContextWrapper from './utils/theme';

Sentry.init({
  dsn: 'https://45bf7af5b3074239af42b54805445cf6@o877739.ingest.sentry.io/6042834',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

ReactDOM.render(
  <ThemeContextWrapper>
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <Provider store={store}>
          <ProvideAuth>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ProvideAuth>
        </Provider>
      </I18nextProvider>
    </React.StrictMode>
  </ThemeContextWrapper>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
