import { createContext, useContext } from 'react';

export const themes = {
  dark: '',
  light: 'white-content',
};

export const ThemeContext = createContext({
  theme: themes.dark,
  changeTheme: () => {},
});

export function useTheme() {
  return useContext(ThemeContext);
}
