import { CAMERA_DATA } from '../../constants/actionTypes';

const initialState = [];
const cameraImages = (state, action) => {
  switch (action.type) {
    case CAMERA_DATA:
      return [...state, action.payload];

    default:
      return [];
  }
};

export default cameraImages;
