import { OUTPUT_IMAGES } from '../../constants/actionTypes';

const initalState = {};

const analysisResult = (state = initalState, { type, payload }) => {
  switch (type) {
    case OUTPUT_IMAGES:
      return {
        ...state,
        analysisId: payload[0],
        batchId: payload[1],
        accessToken: payload[2],
      };
    default:
      return state;
  }
};

export default analysisResult;
