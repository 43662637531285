import { IS_AUTH } from "../../constants/actionTypes";

const initialState = {
  isLogged:
    !!sessionStorage.getItem("isLogged") || !!localStorage.getItem("isLogged"),

  userData: (localStorage.getItem("remember") == "true" ? JSON.parse(localStorage.getItem("userData")) : JSON.parse(sessionStorage.getItem("userData")))
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_AUTH:
      return { ...state, isLogged: true, userData: action.payload };

    default:
      return state;
  }
};

export default authReducer;
